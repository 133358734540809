.card {
  background-color: rgba(0, 0, 0, 0.3);
  border: 4px #55fc4f double;
  border-radius: 8px;
  box-shadow: 0 0 8px black;

  transition: transform 0.3s ease, opacity 0.3s ease; /* Animasyon geçişi ekleyelim */

  &:hover {
    transform: scale(1.01); /* Kartı biraz büyüt */
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 12px #55fc4f; /* Hafif bir parıltı efekti */
  }
}
