@import "theme.scss";

// variables overwrites before this line
@import "bootstrap/scss/bootstrap.scss";
// class overwrites after this line

body {
    background-image: url('../img/background.png');
    background-repeat: repeat;
    background-attachment: fixed;
}

.box-shadow-dark {
    box-shadow: 0 0 2px $matrix-dark, 0 0 4px $matrix-dark, 0 0 1rem $matrix-dark, 0 0 4rem $matrix-dark;
}

.main-page-button {
    width: 200px;
}

.bg-article {
    background-color: rgba(0, 0, 0, 0.5)
}

a {
    text-decoration: none;
}

